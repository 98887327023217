import { escapeRegExp, map } from 'lodash';

export function convertGlobToRegexp(glob: string): RegExp {
  return new RegExp(
    glob
      .replace(/(?:\*+\/)*\*/g, '*') // replace all sequences of {*, /} with just *
      .replace(/\*/g, '__GREEDY_STAR__') // replace all * and ** with a placeholder
      .split('/')
      .map(escapeString) // escape all the parts
      .join('/')
      .replace(/\//g, '\\/') // escape all slashes
      .replace(/__GREEDY_STAR__/g, '.*') // replace the placeholder with a greedy star
      .concat('$'),
    'i' // case insensitive
  );
}

const PREFIXED_GROUP = /(.*)\{([^}]+)\}/;
const ALL_PREFIXED_GROUPS = new RegExp(PREFIXED_GROUP, 'g');

function escapeString(str: string): string {
  const prefixedGroups = str.match(ALL_PREFIXED_GROUPS) ?? [];

  const escapedGroups = map(prefixedGroups, (text) => {
    let [, prefix, list] = text.match(PREFIXED_GROUP) ?? [];

    prefix = escapeRegExp(prefix);
    list = list
      .split(',')
      .map((part) => `(?:${escapeRegExp(part)})`)
      .join('|');

    return `${prefix}(?:${list})`;
  });

  const [, suffix] = str.match(/(?:.*\})?(.*)$/) ?? [];

  return [...escapedGroups, escapeRegExp(suffix)].join('');
}
