import { findKey, random, sum } from 'lodash';

export function probabilitySample<T extends string>(
  probabilities: Record<T, number>
): T {
  const total = sum(Object.values(probabilities));
  const selection = random(0, total);

  let acc = 0;

  return findKey(probabilities, (value) => {
    acc += value;
    return selection <= acc;
  }) as T;
}
