import { isEmpty } from 'lodash';

export function valueOrDefault<T>(
  value: T,
  defaultValue: Exclude<T, null | undefined>
): Exclude<T, null | undefined> {
  return isEmpty(value)
    ? defaultValue
    : (value as Exclude<T, null | undefined>);
}
