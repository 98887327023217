import { DateTime } from 'luxon';

interface CacheItem<V> {
  value: V;
  expiresAt: DateTime;
}

export class Cache<K extends string, V> {
  private readonly cache = new Map<string, CacheItem<V>>();

  public get(key: K): V | undefined {
    const item = this.cache.get(key);

    return this.isItemExpired(item) ? undefined : item.value;
  }

  public set(key: K, value: V, expiresAt: DateTime): void {
    this.evictExpiredCacheItems();

    this.cache.set(key, { value, expiresAt });
  }

  public delete(key: K): void {
    this.evictExpiredCacheItems();

    this.cache.delete(key);
  }

  private isItemExpired(item: CacheItem<V> | undefined): item is undefined {
    return !item || item.expiresAt < DateTime.now();
  }

  private evictExpiredCacheItems(): void {
    const expiredKeys = Array.from(this.cache.keys()).filter((key) =>
      this.isItemExpired(this.cache.get(key))
    );

    for (const key of expiredKeys) {
      this.cache.delete(key);
    }
  }
}
