import { ComparisonOperator } from './compare';

export class Operator {
  id: ComparisonOperator;
  name: string;
  symbol: string;
  constructor(id: ComparisonOperator, name: string, symbol: string) {
    this.id = id;
    this.name = name;
    this.symbol = symbol;
  }
}

export const OPERATORS: Operator[] = [
  new Operator('<', 'Less Than', '<'),
  new Operator('<=', 'Less Than or Equal To', '≤'),
  new Operator('=', 'Equal To', '='),
  new Operator('!=', 'Not Equal To', '≠'),
  new Operator('>=', 'Greater Than or Equal To', '≥'),
  new Operator('>', 'Greater Than', '>')
];
