import { Paginated } from '../types';
import { isPaginated } from './predicates';

export function getRecords<T>(data: Paginated<T> | T[] | T): T[] {
  if (Array.isArray(data)) {
    return data;
  }

  if (isPaginated(data)) {
    return data.data;
  }

  if (typeof data === 'object') {
    return [data];
  }

  throw new Error(
    'Invalid data type. Data should be an array of objects, a paginated object, or a single object.'
  );
}
