import { Paginated } from '../../types';

export function isPaginated<T = any>(data: any): data is Paginated<T> {
  return (
    data &&
    !Array.isArray(data) &&
    typeof data.total !== 'undefined' &&
    typeof data.limit !== 'undefined' &&
    typeof data.skip !== 'undefined' &&
    typeof data.data !== 'undefined'
  );
}
