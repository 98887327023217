import { DateTime, ZoneOptions } from 'luxon';
import {
  DateTimeFormatName,
  DateTimeFormats
} from '../../constants/datetime-formats';
import { TimezoneValue } from '../../facility-settings/constants';
import { Logger } from '../../logger';
import { ToLuxonParam, toLuxon } from './to-luxon';

type Options = {
  parseFormatNames?: DateTimeFormatName[];
  parseFormats?: string[];
  createIfFalsy?: boolean;
  zoneOptions?: ZoneOptions;
};

export class FacilityTime {
  protected _timezone: TimezoneValue;

  constructor(timezone: TimezoneValue) {
    this._timezone = timezone;
  }

  get timezone(): TimezoneValue {
    return this._timezone;
  }

  private _convertDateTime(
    datetime?: ToLuxonParam,
    options?: Options
  ): DateTime {
    const parseFormats =
      options?.parseFormatNames?.map((f) => DateTimeFormats[f] as string) ??
      options?.parseFormats;

    if (!datetime && options?.createIfFalsy) {
      return DateTime.local({ zone: this.timezone });
    }

    Logger.assert(datetime, 'datetime is falsy and createIfFalsy is false');

    return toLuxon(datetime, parseFormats).setZone(
      this.timezone,
      options?.zoneOptions
    );
  }

  /**
   * Converts a datetime to a DateTime in the facility timezone.
   * @param datetime - The datetime to convert.
   * @param options - The options to use when converting.
   * @returns The converted datetime.
   * @throws Will throw an error if the input or format is invalid.
   */
  convertDateTime(datetime: ToLuxonParam, options?: Options): DateTime {
    return this._convertDateTime(datetime, options);
  }

  /**
   * Converts a datetime to a DateTime in the facility timezone, keeping the local time.
   * @param datetime - The datetime to convert.
   * @param options - The options to use when converting.
   * @returns The converted datetime.
   * @throws Will throw an error if the input or format is invalid.
   */
  convertDateTimeKeepingLocalTime(
    datetime: ToLuxonParam,
    options?: Options
  ): DateTime {
    return this._convertDateTime(datetime, {
      ...options,
      zoneOptions: { keepLocalTime: true }
    });
  }

  /**
   * Creates a DateTime object. Falls back to current time if no datetime is provided.
   * @param datetime - The datetime to convert.
   * @returns The created DateTime object.
   */
  createDateTime(datetime?: ToLuxonParam, options?: Options): DateTime {
    return this._convertDateTime(datetime, {
      ...options,
      createIfFalsy: true
    });
  }

  /**
   * Creates a DateTime object for the beginning of a date.
   * Falls back to current date if no date is provided.
   * @param date - The date to convert.
   * @param options - The options to use when converting.
   * @returns The created DateTime object.
   */
  createDate(date?: ToLuxonParam, options?: Options): DateTime {
    const luxonDate = this._convertDateTime(date, {
      ...options,
      createIfFalsy: true,
      zoneOptions: { keepLocalTime: true }
    });

    return luxonDate.startOf('day');
  }

  /**
   * Parses a time string and optionally combines it with a date.
   * @param time - The time string to parse.
   * @param date - The date to combine with the time.
   * @returns The parsed DateTime object.
   */
  parseTime(time: string, date?: ToLuxonParam): DateTime {
    let luxonTime = this.convertDateTimeKeepingLocalTime(time, {
      parseFormatNames: ['TIME_12']
    });

    if (date) {
      const luxonDate = this.convertDateTime(date);
      luxonTime = luxonTime.set({
        year: luxonDate.year,
        month: luxonDate.month,
        day: luxonDate.day
      });
    }

    return luxonTime;
  }
}
