import { DurationLikeObject, DurationObjectUnits } from 'luxon';

import { Logger } from '../../logger';
import { FacilityTime } from './facility-time';
import { ToLuxonParam } from './to-luxon';

type PartialDurationObjectUnits = keyof Pick<
  DurationObjectUnits,
  'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds'
>;

export interface RelativeTimeThresholds {
  seconds: number;
  minutes: number;
  hours: number;
  weeks: number;
  days: number;
  months: number;
  years: number;
}

export interface RelativeTimeOptions {
  thresholds: RelativeTimeThresholds;
  ft: FacilityTime;
}

export interface RelativeTimeUntilOptions extends RelativeTimeOptions {
  /**
   * A specific string format for the datetimes past the threshold
   */
  format?: string;
  /**
   * Whether or not to return a formatted datetime string if the datetime is past the threshold. Default false.
   */
  noDatePastThreshold?: boolean;
}

const relativeTimeDefaultOptions: RelativeTimeOptions = {
  thresholds: {
    seconds: 60,
    minutes: 60,
    hours: 24,
    days: 14,
    weeks: 4,
    months: 12,
    years: 4
  },
  ft: new FacilityTime('America/Los_Angeles')
};

const thresholdOrder: PartialDurationObjectUnits[] = [
  'seconds',
  'minutes',
  'hours',
  'days',
  'weeks',
  'months',
  'years'
];

export function relativeTime(
  datetime: ToLuxonParam,
  options: Partial<RelativeTimeOptions> = {}
): string {
  const opts: RelativeTimeOptions = {
    ...relativeTimeDefaultOptions,
    ...options,
    thresholds: {
      ...relativeTimeDefaultOptions.thresholds,
      ...(options.thresholds ?? {})
    }
  };
  if (!datetime) {
    return '';
  }

  Logger.assert(options.ft, 'FacilityTime is required');
  const dt = options.ft.convertDateTime(datetime);

  const unit = thresholdOrder.find((unit) => {
    const timeDifference = Math.abs(dt.diffNow(unit).as(unit));
    const timeThreshold = opts.thresholds[unit];

    return timeDifference < timeThreshold;
  });

  return dt.toRelative({ unit });
}

export function relativeTimeUntil(
  datetime: ToLuxonParam,
  threshold: DurationLikeObject,
  options: Partial<RelativeTimeUntilOptions>
): string {
  const format = options?.format ?? "DD 'at' t";
  const noDatePastThreshold = options?.noDatePastThreshold ?? false;

  const unit = Object.keys(threshold)[0] as keyof DurationLikeObject;
  Logger.assert(options.ft, 'FacilityTime is required');
  const dt = options.ft.convertDateTime(datetime);
  const diff = Math.abs(dt.diffNow(unit).as(unit));

  if (diff < threshold[unit]!) {
    return relativeTime(dt, options);
  } else if (noDatePastThreshold) {
    return '';
  }

  return dt.toFormat(format);
}
