import { isFunction } from 'lodash';

export type LazyProxy = {
  __isLazy: true;
  __clearLazy: () => void;
};

export function Lazy<T>(factory: () => T): T & LazyProxy {
  let value: any;

  const reflect = (name: keyof typeof Reflect, args: any[]): any => {
    if (name === 'get') {
      if (args[1] === '__isLazy') {
        return true;
      }

      if (args[1] === '__clearLazy') {
        return () => {
          value = undefined;
        };
      }
    }

    // The wrapped value is created *once*, the first time it is accessed through the proxy.
    value ??= factory();

    try {
      if (
        (typeof value !== 'object' && typeof value !== 'function') ||
        value === null ||
        value === undefined
      ) {
        throw new Error(
          'Lazy factory return value must be an object or function.'
        );
      }

      // We use Reflect to call the wrapped value's methods.
      // eslint-disable-next-line @typescript-eslint/ban-types
      const fn: Function = Reflect[name];

      // eslint-disable-next-line no-useless-call
      let result = fn.apply(null, [value, ...args.slice(1)]);

      // If the result is a function, we bind it to the wrapped value.
      if (name === 'get' && isFunction(result)) {
        result = result.bind(value);
      }

      return result;
    } catch (error) {
      const newError: any = new Error(
        "Something went wrong in Lazy's proxy handler."
      );

      newError.cause = error;

      throw newError;
    }
  };

  return new Proxy({} as any, {
    apply: (...args) => reflect('apply', args),
    construct: (...args) => reflect('construct', args),
    defineProperty: (...args) => reflect('defineProperty', args),
    deleteProperty: (...args) => reflect('deleteProperty', args),
    get: (...args) => reflect('get', args),
    getOwnPropertyDescriptor: (...args) =>
      reflect('getOwnPropertyDescriptor', args),
    getPrototypeOf: (...args) => reflect('getPrototypeOf', args),
    has: (...args) => reflect('has', args),
    isExtensible: (...args) => reflect('isExtensible', args),
    ownKeys: (...args) => reflect('ownKeys', args),
    preventExtensions: (...args) => reflect('preventExtensions', args),
    set: (...args) => reflect('set', args),
    setPrototypeOf: (...args) => reflect('setPrototypeOf', args)
  });
}
