import {
  castArray,
  flatMap,
  isEmpty,
  isNil,
  map,
  reject,
  sortBy,
  upperFirst
} from 'lodash';
import { DateTime } from 'luxon';
import { Frequency, Options, RRule, Weekday } from 'rrule';
import { IRruleConfig } from '../../rrule.interface';
import { FacilityTime } from '../date-time/facility-time';

const WEEKDAY_NUMBERS = {
  mo: 0,
  tu: 1,
  we: 2,
  th: 3,
  fr: 4,
  sa: 5,
  su: 6
};

const INTERVAL_SUFFIXES = {
  daily: 'days',
  weekly: 'weeks',
  monthly: 'months',
  yearly: 'years'
};

export function describeRRule(rrule: IRruleConfig): string {
  if (rrule.bySetPos ?? rrule.byWeekNo) {
    return 'Custom schedule';
  }

  const parts: string[] = [
    upperFirst(rrule.freq),
    rrule.freq && rrule.interval && rrule.interval > 1
      ? `every ${rrule.interval} ${INTERVAL_SUFFIXES[rrule.freq]}`
      : ''
  ];

  const weekdays = map(rrule.byWeekday, (value, day) =>
    value ? upperFirst(day) : ''
  )
    .filter(Boolean)
    .join(', ');
  parts.push(weekdays);

  const months = map(rrule.byMonth, (value, month) =>
    value ? upperFirst(month) : ''
  )
    .filter(Boolean)
    .join(', ');
  if (months) {
    parts.push(`during ${months}`);
  }

  const monthDays = castArray(rrule.byMonthDay ?? []).join(', ');
  if (monthDays) {
    // TODO: dacarley: support cardinal days, e.g. 1st, 2nd, 3rd, 4th, 5th, 6th, 7th, etc.
    // TODO: dacarley: support day ranges, e.g. 1st-15th
    parts.push(`on the days of the month: ${monthDays}`);
  }

  const times = map(rrule.times ?? [], ({ time }) => {
    const [hour, minute] = time.split(':');
    return DateTime.fromObject({
      hour: Number(hour),
      minute: Number(minute)
    })
      .toLocaleString(DateTime.TIME_SIMPLE)
      .replace(/^0/, '');
  }).join(', ');

  if (times) {
    parts.push(`at ${times}`);
  }

  return parts.filter(Boolean).join(' ');
}

export function getDates(rrule: IRruleConfig, ft: FacilityTime): DateTime[] {
  return generateDates(rrule, ft, (rule) => rule.all());
}

export function getNextOccurrence(
  rrule: IRruleConfig,
  ft: FacilityTime,
  fromDate: Date = new Date()
): DateTime | undefined {
  const dates = generateDates(rrule, ft, (rule) => [rule.after(fromDate)]);

  return isEmpty(dates) ? undefined : DateTime.max(...dates);
}

export function getPreviousOccurrence(
  rrule: IRruleConfig,
  ft: FacilityTime,
  tillDate: Date = new Date()
): DateTime | undefined {
  const dates = generateDates(rrule, ft, (rule) => [rule.before(tillDate)]);

  return isEmpty(dates) ? undefined : DateTime.max(...dates);
}

function generateDates(
  rrule: IRruleConfig,
  ft: FacilityTime,
  ruleMapper: (rrule: RRule) => (Date | null)[]
): DateTime[] {
  if (!rrule.dtstart) {
    return [];
  }

  const rules = createRules(rrule, ft);
  const jsDates = reject(flatMap(rules, ruleMapper), isNil) as Date[];

  const dates = map(jsDates, (date) =>
    ft.convertDateTimeKeepingLocalTime(
      DateTime.fromJSDate(date, { zone: 'utc' })
    )
  );

  return sortBy(dates);
}

function createRules(rrule: IRruleConfig, ft: FacilityTime): RRule[] {
  const options: Partial<Options> = {
    freq: encodeFreq(rrule.freq),
    dtstart: rrule.dtstart
      ? ft
          .createDateTime(rrule.dtstart)
          .setZone('utc', { keepLocalTime: true })
          .startOf('second')
          .toJSDate()
      : null,
    interval: rrule.interval ?? 1,
    wkst: encodeWkst(rrule.wkst),
    count: rrule.count ?? null,
    until: rrule.until
      ? ft
          .createDateTime(rrule.until)
          .setZone('utc', { keepLocalTime: true })
          .startOf('second')
          .toJSDate()
      : null,
    bysetpos: rrule.bySetPos ?? null,
    bymonth: encodeByMonth(rrule.byMonth),
    bymonthday: rrule.byMonthDay ?? null,
    byweekno: rrule.byWeekNo ?? null,
    byweekday: encodeByWeekday(rrule.byWeekday),
    bysecond: 0,
    tzid: 'utc'
  };

  const times = rrule.times ?? [];

  return times.map(({ time }) => {
    const { byhour, byminute } = verifyValidTime(time);

    return new RRule({
      ...options,
      byhour,
      byminute
    });
  });
}

function verifyValidTime(time: string): { byhour: number; byminute: number } {
  const [byhour, byminute] = time.split(':').map(Number);

  switch (true) {
    case !isFinite(byhour):
    case byhour < 0:
    case byhour > 23:
    case !isFinite(byminute):
    case byminute < 0:
    case byminute > 59:
      throw new Error(`Invalid time: ${time}`);
  }

  return {
    byhour,
    byminute
  };
}

function encodeWkst(wkst: IRruleConfig['wkst']): number | null {
  if (!wkst) {
    return null;
  }

  const weekday = WEEKDAY_NUMBERS[wkst];

  if (!weekday) {
    throw new Error(`Unrecognized weekday: ${wkst}`);
  }

  return weekday;
}

function encodeFreq(freq: string | undefined): Frequency {
  switch (freq) {
    case 'yearly':
      return RRule.YEARLY;

    case 'monthly':
      return RRule.MONTHLY;

    case 'weekly':
      return RRule.WEEKLY;

    case 'daily':
    case undefined:
      return RRule.DAILY;

    default:
      throw new Error(`Unrecognized frequency: ${freq}`);
  }
}

function encodeByMonth(byMonth: IRruleConfig['byMonth']): number[] | undefined {
  if (!byMonth) {
    return undefined;
  }

  const months = [
    byMonth.jan && 1,
    byMonth.feb && 2,
    byMonth.mar && 3,
    byMonth.apr && 4,
    byMonth.may && 5,
    byMonth.jun && 6,
    byMonth.jul && 7,
    byMonth.aug && 8,
    byMonth.sep && 9,
    byMonth.oct && 10,
    byMonth.nov && 11,
    byMonth.dec && 12
  ].filter(Boolean) as number[];

  return months.length ? months : undefined;
}

function encodeByWeekday(
  byWeekday: IRruleConfig['byWeekday']
): Weekday[] | undefined {
  const days = [
    byWeekday?.mo && RRule.MO,
    byWeekday?.tu && RRule.TU,
    byWeekday?.we && RRule.WE,
    byWeekday?.th && RRule.TH,
    byWeekday?.fr && RRule.FR,
    byWeekday?.sa && RRule.SA,
    byWeekday?.su && RRule.SU
  ].filter(Boolean) as Weekday[];

  return days.length ? days : undefined;
}
