/**
 * @description Validates the type of a value with the validator function,
 * and narrows that type if the validation passes.
 * @param value the value to validate
 * @param validator function for validating the type of the value
 */
export function isType<T>(
  value: any,
  validator: (v: any) => boolean
): value is T {
  return validator(value);
}
