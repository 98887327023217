import { Logger } from '../logger';

export function createImmutableSet<T>(values: readonly T[]): Set<T> {
  const set = new Set(values);

  set.add = () => Logger.throw('Cannot add to an immutable set');
  set.clear = () => Logger.throw('Cannot clear an immutable set');
  set.delete = () => Logger.throw('Cannot delete from an immutable set');

  return set;
}
