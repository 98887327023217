type Fn = (...args: any[]) => any;

export function bind<T extends Fn>(
  _target: object,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<T>
): TypedPropertyDescriptor<T> | void {
  if (!descriptor || typeof descriptor.value !== 'function') {
    throw new TypeError(
      `Only methods can be decorated with @bind. <${propertyKey}> is not a method!`
    );
  }

  return {
    configurable: true,
    get(this: T): T {
      // Perform the binding on the first access.
      const bound = descriptor.value!.bind(this);

      // Redefine the property to avoid `bind` being called on every access (effectivelly memoizing it)
      Object.defineProperty(this, propertyKey, {
        value: bound,
        configurable: true,
        writable: true
      });

      return bound as T;
    }
  };
}
