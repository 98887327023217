export function Memoize<TArgs extends Array<unknown>, TValue>(
  factory: (...args: TArgs) => TValue
): typeof factory {
  const cache = new Map<string, TValue>();

  return (...args: TArgs) => {
    const key = args.join('');

    if (!cache.has(key)) {
      cache.set(key, factory(...args));
    }

    return cache.get(key) as TValue;
  };
}
