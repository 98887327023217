import { HookContext } from '@feathersjs/feathers';
import { has } from 'lodash';

export function isFeathersHookContext<T extends HookContext = HookContext>(
  value: any
): value is T {
  return (
    has(value, 'app') &&
    has(value, 'service') &&
    has(value, 'path') &&
    has(value, 'method') &&
    has(value, 'type') &&
    has(value, 'params')
  );
}
