export function isValidPhoneNumber(number: string): boolean {
  number = number.replace(/\D/g, '');

  if (number.length > 11 || number.length < 10) {
    return false;
  }

  if (number.length === 11 && number.charAt(0) === '1') {
    number = number.slice(1);
  }

  if (/^[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4}/.test(number)) {
    return true;
  }

  return false;
}
