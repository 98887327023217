import { cleanStack } from './clean-stack';

const EMPTY_RESULT = {
  func: 'Unknown',
  filePath: 'Unknown',
  line: NaN,
  column: NaN,
  raw: 'Stack trace unavailable'
};

export function getCallerInfo(): {
  func: string;
  filePath: string;
  line: number;
  column: number;
  raw: string;
} {
  const stack = cleanStack(new Error().stack);

  if (!stack) {
    return EMPTY_RESULT;
  }

  const callerLine = stack[2];

  if (!callerLine) {
    return {
      ...EMPTY_RESULT,
      raw: stack.join('\n')
    };
  }

  const [, func, fileRaw] = callerLine.match(/(.*) \((.*)\)$/) ?? [];
  const [, line, column] = callerLine.match(/:(\d+):(\d+)/) ?? [];
  const [, filePath] = callerLine.match(/\/(.*):(\d+):(\d+)/) ?? [
    null,
    fileRaw
  ];

  return {
    func,
    filePath,
    line: Number(line),
    column: Number(column),
    raw: callerLine
  };
}
