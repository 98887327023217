import { upperFirst } from 'lodash';

/**
 * An error message should be useful to the end user. Therefore,
 * by structuring our messages to follow this interface our messages
 * will be a better experience.
 *
 * ---
 * The Characteristics of a good error message
 * - A problem. States that a problem occurred.
 * - A cause. Explains why the problem occurred.
 * - A solution. Provides a solution so that users can fix the problem.
 * - A resource. Directs a user to where they can find additional help
 *
 * Additionally, good error messages are presented in a way that is:
 *
 * - **Relevant**: The message presents a problem that users care about.
 * - **Actionable**: Users should either perform an action or change their behavior as the result of the message.
 * - **User-centered**: The message describes the problem in terms of target user actions or goals, not in terms of what the code is unhappy with.
 * - **Brief**: The message is as short as possible, but no shorter.
 * - **Clear**: The message uses plain language so that the target users can easily understand problem and solution.
 * - **Specific**: The message describes the problem using specific language, giving specific names, locations, and values of the objects involved.
 * - **Courteous**: Users shouldn't be blamed or made to feel stupid.
 * - **Rare**: Displayed infrequently. Frequently displayed error messages are a sign of bad design.
 */
export class ErrorMessage {
  /**
   * The problem, solution and help messages combined into one message in sentence notation
   */
  message: string;

  /**
   * @param {string} problem - message that states the problem and cause for the error
   * @param {string} [solution=''] - message that states the solution/workaround the user can employ to resolve the problem.
   * @param {string} [help=''] - message that states where additional help can be found
   * @param {string} [resource=''] - url to the specific help resource, general help page, or support contact page
   * @memberof ErrorMessage
   */
  constructor(
    public problem: string,
    public solution: string = '',
    public help: string = '',
    public resource: string = ''
  ) {
    this.message = [
      this.#cleanSentence(problem),
      this.#cleanSentence(solution),
      this.#cleanSentence(help)
    ]
      .filter(Boolean)
      .join('\n\n');
  }

  toString(): string {
    return this.message;
  }

  toJSON(): any {
    const result: any = {
      message: this.message,
      problem: this.problem
    };

    if (this.solution) {
      result.solution = this.solution;
    }

    if (this.help) {
      result.help = this.help;
    }

    if (this.resource) {
      result.resource = this.resource;
    }

    return result;
  }

  #cleanSentence(sentence: string): string {
    return (
      sentence &&
      upperFirst(
        sentence[sentence.length - 1] === '.'
          ? sentence.trim()
          : `${sentence.trim()}.`
      )
    );
  }
}
