export const StageNames = [
  'develop',
  'test',
  'staging',
  'production',
  'ci'
] as const;
export type StageName = (typeof StageNames)[number];

export const StageNameChoices = ['develop', 'staging', 'production'] as const;

export function parseStageName(value: string): StageName {
  if (isStageName(value)) {
    return value;
  }

  throw new Error(`Invalid stage name: "${value}"`);
}

function isStageName(value: any): value is StageName {
  return (
    value === 'ci' ||
    value === 'develop' ||
    value === 'test' ||
    value === 'staging' ||
    value === 'production'
  );
}
