import { flatMap, groupBy } from 'lodash';

type LevelId = 'severe' | 'moderate' | 'mild';
type hasLevelIdAndName = {
  levelId: LevelId;
  name: string;
};

/** 
@description Sorts collection by severity, then alphabetically. Returns a new array.
@param collection - The collection of objects with both a levelId and name property.
@returns A new array sorted by severity and name.
*/
export function sortBySeverityAndName<T extends hasLevelIdAndName>(
  collection: T[]
): T[] {
  const sorted = collection.sort((a, b) => a.name.localeCompare(b.name));
  const groups = groupBy(sorted, 'levelId') as Record<LevelId, T[]>;

  return flatMap([
    groups.severe ?? [],
    groups.moderate ?? [],
    groups.mild ?? []
  ]);
}
